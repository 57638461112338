exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-cloud-platform-build-and-migration-index-js": () => import("./../../../src/pages/services/cloud-platform-build-and-migration/index.js" /* webpackChunkName: "component---src-pages-services-cloud-platform-build-and-migration-index-js" */),
  "component---src-pages-services-data-platforms-and-analytics-index-js": () => import("./../../../src/pages/services/data-platforms-and-analytics/index.js" /* webpackChunkName: "component---src-pages-services-data-platforms-and-analytics-index-js" */),
  "component---src-pages-services-design-and-development-index-js": () => import("./../../../src/pages/services/design-and-development/index.js" /* webpackChunkName: "component---src-pages-services-design-and-development-index-js" */),
  "component---src-pages-services-digital-transformation-index-js": () => import("./../../../src/pages/services/digital-transformation/index.js" /* webpackChunkName: "component---src-pages-services-digital-transformation-index-js" */),
  "component---src-pages-services-implementation-of-iot-index-js": () => import("./../../../src/pages/services/implementation-of-iot/index.js" /* webpackChunkName: "component---src-pages-services-implementation-of-iot-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-security-and-devsecops-index-js": () => import("./../../../src/pages/services/security-and-devsecops/index.js" /* webpackChunkName: "component---src-pages-services-security-and-devsecops-index-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

